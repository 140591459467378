body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.moDal-Popup:before {
  content: "";
  position: absolute;
  background: #d32f2f;
  width: 100%;
  height: 20%;
  left: 0;
  top: 0;
  border-radius: 0% 0% 250% 0%;
  z-index: -2;
}
.moDal-Popup {
  background-color: #ffffffb0;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  padding: 10px 25px;
}

.MuiBox-root.css-xqtssz:before {
  content: "";
  position: absolute;
  background: #d32f2f;
  width: 100%;
  height: 20%;
  left: 0;
  top: 0;
  border-radius: 0% 0% 250% 0%;
  z-index: -1;
}

.MuiBox-root.css-xqtssz {
  position: relative;
}

.css-xqtssz {
  border-radius: 0px;
}

